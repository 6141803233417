import * as React from 'react';
import Viewer from 'react-viewer';
import { KTSVG } from '../../../../../../../../../../_metronic/helpers'
import { BiImages } from 'react-icons/bi';

interface IViewImageProps {
    propsimages: any
}

const ViewImage: React.FunctionComponent<IViewImageProps> = ({ propsimages }) => {


    const newImageData = propsimages.map((img: string) => {
        return {
            src: img
        }
    })


    const [visible, setVisible] = React.useState(false);
    const [images, setImages] = React.useState(newImageData);


    return <>
        <div>{
            propsimages instanceof Array && propsimages?.length > 0 ?
                <span onClick={() => setVisible(true)} className='cursor-pointer'>
                    {/* <ImImages size="20" className="text-success" /> */}
                    <BiImages size={16} className="text-primary" />
                </span> : <span className=''>
                    {/* <ImImages size="20" className='text-muted' /> */}
                    <BiImages size={16} className="text-muted" />
                </span>
        }</div>
        <Viewer
            visible={visible}
            onClose={() => { setVisible(false) }}
            images={images}
        />
    </>;
};

export default ViewImage;


{/* <div className='text-success' onClick={() => setVisible(true)} >Image</div> */ }