
import React, { useState } from 'react'

// import {IProfileDetails, } from '../SettingsModel'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers';
import { DisconnectionReportState } from '../Context';
import { RECONNECTION_REASON_CODES } from '../../../../../Commons/Helpers/HelpersMain';


type ReconnectionFormProps = {
    onFormSubmit?: (formData: any) => void;
    onCancel?: (isCanceled: boolean) => void;
    isError?: boolean;
    errorMsg?: string;
};

type IReconnectionDetails = {
    consumer_no: string | ''
    out_standing_amt: string | ''
    paid_amt: string | ''
    receipt_no: string | '',
    reconnection_fees: string | ''
    order_reason_code: string,
    order_remark: string,
    isLOR: boolean | false,
    lor_no: string | '',
    parent_order_no: string | '',
    disconnection_id: string | '',
    is_reconnected: boolean | true,
}


const formInitialValues: IReconnectionDetails = {

    consumer_no: '',
    out_standing_amt: '',
    paid_amt: '',
    receipt_no: '',
    reconnection_fees: '',
    order_reason_code: "",
    order_remark: "",
    isLOR: false,
    lor_no: '',
    parent_order_no: '',
    disconnection_id: '',
    is_reconnected: true,


}


// .test("fileSize", "The file is too large", (value) => {
//     console.log({ value });
//     return value && value.every((v: any) => v.fileSize <= 2000000)
// })
// .test("type", "Only the following formats are accepted: .jpeg, .jpg ,.png", (value) => {
//     return value && value.every((v: any) => v.type === "image/jpeg" || v.type === "image/png");
// }),

const profileDetailsSchema = Yup.object().shape({
    consumer_no: Yup.string().required('Consumer No. is required'),
    paid_amt: Yup.string().required('Paid Amount is required'),
    receipt_no: Yup.string().required('Receipt Number is required'),
    reconnection_fees: Yup.string().optional().label('Receipt Fees '),
    // order_reason_code: Yup.string().required('Order reason code is required'),
    order_remark: Yup.string().required('remark is required'),
    isLOR: Yup.boolean().optional(),
    lor_no: Yup.number().when("isLOR", {
        is: true,
        then: Yup.number().required().label('LOR NO. is required'),
        otherwise: Yup.number().optional().label('LOR NO. is required')
    })
})


const ReconnectionForm = ({ onFormSubmit, onCancel, isError, errorMsg }: ReconnectionFormProps) => {

    const [data, setData] = useState<IReconnectionDetails>(formInitialValues)

    const [errors, seterrors] = useState({
        errorMsg: ''
    })

    React.useEffect(() => {
        seterrors({
            errorMsg: ''
        })
        return () => { }
    }, [])


    React.useEffect(() => {
        if (isError && errorMsg) {
            seterrors({
                errorMsg: errorMsg
            });
        }
        return () => { }
    }, [errorMsg])


    const [loading, setLoading] = useState(false)

    const { state, updateState } = DisconnectionReportState();

    // console.log({ state });

    // React.useEffect(() => {
    //     isError = ""
    //     errorMsg = ""
    //     return () => { }
    // }, [])

    React.useEffect(() => {
        if (isError) setLoading(false)
    }, [isError, errorMsg])

    React.useEffect(() => {
        if (state.other && state.other?.data) {
            const data = state.other?.data;
            formik.setValues({
                consumer_no: data.consumer_no || '',
                out_standing_amt: data.order_amount || '',
                paid_amt: data.paid_amt || '',
                receipt_no: data.receipt_no || '',
                reconnection_fees: data.reconnection_fees,
                order_reason_code: data.reason_code || '',
                order_remark: data.remarks || '',
                isLOR: data.islor || false,
                lor_no: data.lor_no || '',
                parent_order_no: data?.order_no || '',
                disconnection_id: data?.ref_id || '',
                is_reconnected: true
            });
        }
    }, [state.other])


    const formik = useFormik<IReconnectionDetails>({
        initialValues: formInitialValues,
        onSubmit: (values) => {
            // console.log({ values });
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            }, 1000)

            onFormSubmit && onFormSubmit(values)
        },
        validationSchema: profileDetailsSchema,
    })

    return (
        <>
            <div className='card '>

                <div >
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card-body   '>
                            <div className="row">
                                <div className='col-sm-6'>
                                    <label className='col-form-label  fs-6'>
                                        <span className='text-muted'>Consumer Number</span>
                                    </label>
                                    <div className=' fv-row'>
                                        <label className='fw-bold fs-2'># {formik.values.consumer_no}</label>
                                        {formik.touched.consumer_no && formik.errors.consumer_no && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.consumer_no}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <label className='col-form-label  fs-6'>
                                        <span className='text-muted'>Disconnecion Orders No.</span>
                                    </label>
                                    <div className=' fv-row'>
                                        <label className='fw-bold fs-2'># {formik.values.parent_order_no}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-sm-6 '>
                                    <label className='col-form-label fs-6'>
                                        <span className='optional text-muted'>Outstanding Amt.</span>
                                    </label>
                                    <div className=' fv-row'>
                                        {/* <input
                                            type='tel'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Outstanding amt'
                                            {...formik.getFieldProps('out_standing_amt')}
                                        /> */}
                                        <label className='fw-bold fs-2'>₦ {formik.values.out_standing_amt}</label>
                                        {/* {formik.touched.out_standing_amt && formik.errors.out_standing_amt && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.out_standing_amt}</div>
                                            </div>
                                        )} */}
                                    </div>
                                </div>
                                <div className='col-sm-6 '>
                                    <label className='col-form-label fs-6'>
                                        <span className='required text-muted'>Paid Amt</span>
                                    </label>
                                    <div className=' fv-row'>
                                        <input
                                            type='tel'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Fees'
                                            {...formik.getFieldProps('paid_amt')}
                                        />
                                        {formik.touched.paid_amt && formik.errors.paid_amt && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.paid_amt}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-sm-6 '>
                                    <label className='col-form-label  fs-6'>
                                        <span className='optional text-muted'>Receipt No.</span>
                                    </label>
                                    <div className=' fv-row'>
                                        <input
                                            type='tel'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Receipt Number'
                                            {...formik.getFieldProps('receipt_no')}
                                        />
                                        {formik.touched.receipt_no && formik.errors.receipt_no && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.receipt_no}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <label className='col-form-label  fs-6'>
                                        <span className='optional text-muted'>Reconnection Fees</span>
                                    </label>
                                    <div className=' fv-row'>
                                        <input
                                            type='tel'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Amt (₦)'
                                            {...formik.getFieldProps('reconnection_fees')}
                                        />
                                        {formik.touched.reconnection_fees && formik.errors.reconnection_fees && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.reconnection_fees}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-sm-6 '>
                                    <label className=' col-form-label  fs-6'>
                                        <span className='required text-muted'>Reason </span>
                                    </label>
                                    <div className='fv-row'>
                                        {/* <label className='fs-5'>Reconnection Fees Paid</label> */}
                                        <select
                                            name='reason_filter'
                                            aria-label='Select '
                                            data-control='select2'
                                            data-placeholder='date_period'
                                            className='form-select form-select-sm form-select-solid rounded-0'
                                            value={formik.values.order_reason_code}
                                            onChange={(e) => formik.setFieldValue('order_reason_code', e.target.value)}
                                        >
                                            <option value={''}>Reconnection Fees Paid</option>
                                            {/* <option value={''}>Select Reason</option> */}
                                            {/* {
                                                Object.entries(RECONNECTION_REASON_CODES).map(([key, value]) => {
                                                    return <option key={key.toUpperCase()} value={key}>{value}</option>
                                                })
                                            } */}
                                        </select>
                                        {formik.touched.order_reason_code && formik.errors.order_reason_code && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.order_reason_code}</div>
                                            </div>
                                        )}
                                    </div>
                                    {/* <label className='col-form-label fw-bold fs-6'>
                                        <span className='required'>Is LOR</span>
                                    </label>
                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                        <input className="form-check-input" type="checkbox" value="" id="flexSwitchDefault" />
                                    </div> */}
                                    <div className='fv-row'>
                                        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid d-flex justify-content-between'>
                                            <label className='col-form-label text-muted'>Is LOR</label>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                {...formik.getFieldProps('isLOR')}
                                            />
                                        </div>
                                        {formik.touched.isLOR && formik.errors.isLOR && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.isLOR}</div>
                                            </div>
                                        )}
                                    </div>
                                    {formik.values.isLOR && (
                                        <div className='fv-row'>
                                            <input
                                                type='tel'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='LOR No'
                                                {...formik.getFieldProps('lor_no')}
                                            />
                                            {formik.touched.lor_no && formik.errors.lor_no && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.lor_no}</div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className='col-sm-6 '>
                                    <label className='col-form-label fw-bold fs-6'>
                                        <span className='required text-muted'>Remark</span>
                                    </label>
                                    <div className='fv-row'>
                                        <textarea
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Remark'
                                            rows={5}
                                            {...formik.getFieldProps('order_remark')}
                                        />
                                        {formik.touched?.order_remark && formik.errors?.order_remark && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors?.order_remark}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            errors.errorMsg && <div className='alert alert-danger text-danger d-flex justify-content-between'>
                                <div> Error :<b>{errors.errorMsg}</b></div>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        }

                        <div className='card-footer d-flex justify-content-between py-2 px-9'>
                            <div className='justify-content-end'>
                                {onCancel && <button type='button' className='btn btn-secondary' onClick={() => !isError && onCancel && onCancel(true)} >Cancel
                                </button>}
                            </div>
                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                {!loading && 'Submit'}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
};


export default ReconnectionForm