/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContext,
  ERROR_CODES,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../../../_metronic/helpers'
import { fetchReport } from './_requests'
import { Employes } from './_models'
import { useQueryRequest } from './QueryRequestProvider'
import { ReactPropTypes } from 'react';

const QueryResponseContext = createResponseContext<any>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children, props, type, postdata }) => {

  const { state, updateState } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])


  useEffect(() => {
    updateState({
      order: "desc",
      sort: "event_ts",
      _fkey: 'ALL'
    })
    return () => { }
  }, []);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `RECONNECTION-REPORT-${query}`,
    () => {
      if (state.filter && Object.values(state.filter).length > 0 && (["dist_id", "sc_id", "fd_id", "dss_id", "emp_id"].some((key: any) => Object.keys(state.filter).includes(key)))) {
        return fetchReport(query)
      }
      else return undefined
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false, retry: false, refetchOnMount: false }
  )

  let isError = useMemo(() => ERROR_CODES.includes(response?.code) ? true : false, [response])
  let message = useMemo(() => ERROR_CODES.includes(response?.code) ? response?.message : '', [response])


  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query, isError, message }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()

  if (!response || !response?.payload || !response?.payload?.pagination) {
    return defaultPaginationState
  }

  return response?.payload?.pagination
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
