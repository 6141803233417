import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { useAuth } from '../modules/auth'


const PrivateRoutes = () => {

  const { currentUser } = useAuth();

  const CustomersPages = lazy(() => import('../modules/Customers/RoutePage'))
  const MarketersActionsPages = lazy(() => import('../modules/MarketersActions/RoutePage'))
  const AttendancePage = lazy(() => import('../modules/Employee/RoutePage'))
  const KpireportPages = lazy(() => import('../modules/KPIReports/RoutePage'))
  const KpiFeederReportPages = lazy(() => import('../modules/Feeders/RoutePage'))
  const TransfomereportPages = lazy(() => import('../modules/TransfomersReport/RoutePage'))
  const ActivityreportPages = lazy(() => import('../modules/ActivityReport/RoutePage'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const AdhocDcPage = lazy(() => import('../modules/AdhocDc/RoutePage'))
  const AdhocRcPage = lazy(() => import('../modules/AdhocRc/RoutePage'))
  const LocationManagement = lazy(() => import('../modules/CRUDOperations/RoutePage'))
  const CollectionDashboard = lazy(() => import('../modules/CollectionDashboard/RoutePage'))
  const GisDashboard = lazy(() => import('../modules/GIS/RoutePage'))
  const UnservedGisDashboard = lazy(() => import('../modules/UnServedConsumersGIS/RoutePage'))
  const MobileReport = lazy(() => import('../modules/MobileActivityReports/RoutePage'))
  const ObservationsReport = lazy(() => import('../modules/Observations/RoutePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/Chats/ChatPage'))
  const NotificationDashboard = lazy(() => import('../modules/Notification/RoutePage'))
  const MeterReadingDashboard = lazy(() => import('../modules/MeterReading/RoutePage'))
  const TrainingDashboard = lazy(() => import('../modules/TrainingVideos/RoutePage'))
  const ChangeReqDashboard = lazy(() => import('../modules/ChangeRequest/RoutePage'))
  const GamificationDashboardNew = lazy(() => import('../modules/GamificationNew/RoutePage'))
  const Offerse = lazy(() => import('../modules/Offers/RoutePage'))
  const CurrentBillDashboard = lazy(() => import('../modules/CurrentMonthCollection/RoutePage'))

  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<Navigate to='/employees/attendance/dashboard' />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}


        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        /> */}

        <Route
          path='employees/*'
          element={
            <SuspensedView>
              <AttendancePage />
            </SuspensedView>
          }
        />

        <Route
          path='feeders/*'
          element={
            <SuspensedView>
              <KpiFeederReportPages />
            </SuspensedView>
          }
        />

        <Route
          path='customers/pages/*'
          element={
            <SuspensedView>
              <CustomersPages />
            </SuspensedView>
          }
        />

        <Route
          path='marketers-actions/pages/*'
          element={
            <SuspensedView>
              <MarketersActionsPages />
            </SuspensedView>
          }
        />

        <Route
          path='/mobile-actions/pages/*'
          element={
            <SuspensedView>
              <MobileReport />
            </SuspensedView>
          }
        />

        <Route
          path='colections/pages/*'
          element={
            <SuspensedView>
              <CollectionDashboard />
            </SuspensedView>
          }
        />

        <Route
          path='gis/pages/*'
          element={
            <SuspensedView>
              <GisDashboard />
            </SuspensedView>
          }
        />

        <Route
          path='unserved/gis/pages/*'
          element={
            <SuspensedView>
              <UnservedGisDashboard />
            </SuspensedView>
          }
        />

        <Route
          path='/crud-operations/pages/*'
          element={
            <SuspensedView>
              <LocationManagement />
            </SuspensedView>
          }
        />

        <Route
          path='/kpi/pages/*'
          element={
            <SuspensedView>
              <KpireportPages />
            </SuspensedView>
          }
        />

        <Route
          path='/notification/pages/*'
          element={
            <SuspensedView>
              <NotificationDashboard />
            </SuspensedView>
          }
        />
        <Route
          path='/meter-reading/pages/*'
          element={
            <SuspensedView>
              <MeterReadingDashboard />
            </SuspensedView>
          }
        />

        
        <Route
          path='/change-request/pages/*'
          element={
            <SuspensedView>
              <ChangeReqDashboard />
            </SuspensedView>
          }
        /> 
        {/* : <Route path='dashboard' element={<Navigate to='/employees/attendance/dashboard' />} />} */}

        <Route
          path='/training/pages/*'
          element={
            <SuspensedView>
              <TrainingDashboard />
            </SuspensedView>
          }
        />

        <Route
          path='/reports/transfomers/*'
          element={
            <SuspensedView>
              <TransfomereportPages />
            </SuspensedView>
          }
        />

        <Route
          path='/activityreport/pages/*'
          element={
            <SuspensedView>
              <ActivityreportPages />
            </SuspensedView>
          }
        />


        <Route
          path='/chats/pages/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />


        <Route
          path='observation/reports/*'
          element={
            <SuspensedView>
              <ObservationsReport />
            </SuspensedView>
          }
        />

        {/* <Route
          path='/gamification/pages/*'
          element={
            <SuspensedView>
              <GamificationDashboard />
            </SuspensedView>
          }
        /> */}

        <Route
          path='/gamification-new/pages/*'
          element={
            <SuspensedView>
              <GamificationDashboardNew />
            </SuspensedView>
          }
        />



        <Route
          path='/offers/pages/*'
          element={
            <SuspensedView>
              <Offerse />
            </SuspensedView>
          }
        />

        <Route
          path='/current-month-collection/pages/*'
          element={
            <SuspensedView>
              <CurrentBillDashboard />
            </SuspensedView>
          }
        />

        <Route
          path='/adhoc-dc/pages/*'
          element={
            <SuspensedView>
              <AdhocDcPage />
            </SuspensedView>
          }
        />

        <Route
          path='/adhoc-rc/pages/*'
          element={
            <SuspensedView>
              <AdhocRcPage />
            </SuspensedView>
          }
        />



        {/* <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
