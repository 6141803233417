import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// define a service user a base URL

// const API_URL = process.env.REACT_APP_API_URL_AUTH_SERVICE + "/api/v1/auth";

//const API_URL = process.env.REACT_APP_API_URL;

const API_URL =  process.env.REACT_APP_API_URL_AUTH_SERVICE

const GET_USER_BY_ACCESSTOKEN_URL = `/verify_token`
const LOGIN_URL = `/login`
const REGISTER_URL = `/register`
const REQUEST_PASSWORD_URL = `/forgot_password`

//const { socket, setMembers, members, setCurrentRoom, setRooms, privateMemberMsg, rooms, setPrivateMemberMsg, currentRoom, setMessages, messages, } = useContext(AppContext) as AppContextInterface;

// socket.off("new-user").on("new-user", (payload: any) => {
//     setMembers(payload);
//     const findUser = payload.filter((p: any) => p.email == user.email)[0];
//     saveAuth({ ...user, _id: findUser._id });
// });


const appApi = createApi({
    reducerPath: "appApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
    }),

    endpoints: (builder) => ({
        // creating the user
        signupUser: builder.mutation({
            query: (user) => ({
                url: LOGIN_URL,
                method: "POST",
                body: user,
            }),
        }),

        // login
        loginUser: builder.mutation({
            query: (user) => ({
                url: LOGIN_URL,
                method: "POST",
                body: user,
            }),
        }),

        // logout

        logoutUser: builder.mutation({
            query: (payload) => ({
                url: "/users/logout",
                method: "POST",
                body: payload,
            }),
        }),

    }),
});

export const { useSignupUserMutation, useLoginUserMutation, useLogoutUserMutation } = appApi;

export default appApi;
