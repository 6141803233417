/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const intl = useIntl()

  const { currentUser } = useAuth();
  console.log(currentUser)

  return (
    <>
      {/* <AsideMenuItem
        to='/employees/attendance/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      /> */}
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'><b className='text-primary'>Overview</b></span>
        </div>
      </div>

      <AsideMenuItem to='/overview/pages/colllection-coverage-analysis' title='KPI Report' hasBullet={false} icon='/media/icons/duotune/art/art002.svg' /> */}


      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Historical-Charts'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/reports' title='Reports' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

      </AsideMenuItemWithSub> */}




      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'><b className='text-primary'>Attendance</b></span>
        </div>
      </div>
      <AsideMenuItem to='/employees/attendance/dashboard' title='Dashboard' hasBullet={false} icon='/media/icons/duotune/general/gen049.svg' />


      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'><b className='text-primary'>Analytical Reports</b></span>
        </div>
      </div>

      <AsideMenuItem to='/colections/pages/' title='Performance Report' hasBullet={true} />
      <AsideMenuItem to='/colections/pages/collection-report' title='Collections Report' hasBullet={true} />
      <AsideMenuItem to='/kpi/pages/kpi-report' title='KPI Report' hasBullet={true} />
      <AsideMenuItem to='/activityreport/pages/activity-report' title='Daily Action Report' hasBullet={true} />
      <AsideMenuItem to='/gamification-new/pages/employee-reward' title='Employee Reward' hasBullet={true} />
      {/* <AsideMenuItem to='/colections/pages/transformer-report' title='Transfromer Report' hasBullet={true} />
      <AsideMenuItem to='/colections/pages/service-center-report' title='Service Center Report' hasBullet={true} /> */}



      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'><b className='text-primary'>GIS </b></span>
        </div>
      </div>
      <AsideMenuItem to='/gis/pages/dashboard' title='GIS Dashboard' hasBullet={true} />
      <AsideMenuItem to='unserved/gis/pages/unserved-dashboard' title='Unserved Customers' hasBullet={true} />

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'><b className='text-primary'>Marketers Actions</b></span>
        </div>
      </div>
      <AsideMenuItem to='/marketers-actions/pages/duplicate-bills' title='Bill Delivery' hasBullet={true} /> */}

      {/* //Duplicate Bills */}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'><b className='text-primary'>Customers Action</b></span>
        </div>
      </div>
      {/* <AsideMenuItem to='/customers/pages/actions/disconnections' title='Disconnection' hasBullet={true} /> */}
      <AsideMenuItem to='/customers/pages/actions/disconnection' title='Disconnection' hasBullet={true} />
      <AsideMenuItem to='/customers/pages/actions/reconnection' title=' Re-connection' hasBullet={true} />
      <AsideMenuItem to='/adhoc-dc/pages/report' title=' Adhoc Orders' hasBullet={true} />
      {/* <AsideMenuItem to='/customers/pages/actions/disconnection-report' title='Report' hasBullet={true} />
      <AsideMenuItem to='/customers/pages/actions/disconnection-actions-report' title='Actions Report' hasBullet={true} /> */}

      {currentUser && (currentUser?.designation == "CONTROL_ROOM_MANAGER" || currentUser?.designation == "CONTROL_ROOM_EXECUTIVE") ? <>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-white text-uppercase fs-8 ls-1'><b className='text-primary'>Change Request</b></span>
          </div>
        </div>
        <AsideMenuItem to= '/change-request/pages/report' title='Report' hasBullet={true} />
        <AsideMenuItem to='/change-request/pages/approved' title='Approved Report' hasBullet={true} />
        <AsideMenuItem to='/change-request/pages/audit' title='Audit Report' hasBullet={true} />
      </>
        : null
      }



      {/* 
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'><b className='text-primary'>Re-Connection</b></span>
        </div>
      </div> */}

      {/* <AsideMenuItem to='/customers/pages/actions/reconnection-report' title=' Report' hasBullet={true} /> */}


      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'><b className='text-primary'>Observations</b></span>
        </div>
      </div>
      <AsideMenuItem to='/observation/reports/unpaid-customers' title='HNP Report' hasBullet={true} />
      <AsideMenuItem to='/observation/reports/ppm' title='PPM Report' hasBullet={true} />
      <AsideMenuItem to='/observation/reports/suspended' title='Suspended' hasBullet={true} />
      <AsideMenuItem to='/observation/reports/visits' title='Visits' hasBullet={true} />
      <AsideMenuItem to='/observation/reports/bill-delivered' title='Bill Delivery' hasBullet={true} />
      <AsideMenuItem to='/observation/reports/ASAP' title='ASAP' hasBullet={true} />
      <AsideMenuItem to='/current-month-collection/pages/current-collection' title='Collection' hasBullet={true} />
      <AsideMenuItem to='/observation/reports/cr-followup' title='CR-Followup' hasBullet={true} />
      <AsideMenuItem to='/observation/reports/rpg-events' title='RPG Events' hasBullet={true} />


      {/* 
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'><b className='text-primary'>Historical Charts</b></span>
        </div>
      </div>
      <AsideMenuItem to='/crafted/pages/profile/reports' title='Historical (5 yrs)' hasBullet={false} icon='/media/icons/duotune/art/art002.svg' /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'><b className='text-primary'>Customers</b></span>
        </div>
      </div>
      <AsideMenuItem to='/crud-operations/pages/consumers' title='Report' hasBullet={true} />

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'><b className='text-primary'>Notifications</b></span>
        </div>
      </div>
      <AsideMenuItem to='/notification/pages/send-notification' title='Send & Fetch' hasBullet={true} /> */}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'><b className='text-primary'>Meter Reading</b></span>
        </div>
      </div>
      <AsideMenuItem to='/meter-reading/pages/report' title='Report' hasBullet={true} />
      <AsideMenuItem to='/meter-reading/pages/approved' title='Approved Report' hasBullet={true} />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'><b className='text-primary'>Training</b></span>
        </div>
      </div>
      <AsideMenuItem to='/training/pages/training-videos' title='Videos' hasBullet={true} />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'><b className='text-primary'>Location Mapping</b></span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to='/crud-operations/pages'
        title='Location Mapping'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/crud-operations/pages/regions' title='Regions' hasBullet={true} />
        <AsideMenuItem to='/crud-operations/pages/states' title='States' hasBullet={true} />
        <AsideMenuItem to='/crud-operations/pages/districts' title='Districts' hasBullet={true} />
        <AsideMenuItem to='/crud-operations/pages/service-center' title='Service Center' hasBullet={true} />
        <AsideMenuItem to='/crud-operations/pages/feeders' title='Feeders' hasBullet={true} />
        <AsideMenuItem to='/crud-operations/pages/transformers' title='Transformers' hasBullet={true} />
        {/* <AsideMenuItem to='/crud-operations/pages/EmployeesToRoute' title='Bookcode Maping' hasBullet={true} /> */}
        <AsideMenuItem to='/crud-operations/pages/bookcode' title='Bookscodes' hasBullet={true} />
        <AsideMenuItem to='/crud-operations/pages/employees' title='Employees' hasBullet={true} />
        {/* <AsideMenuItem to='/crud-operations/pages/consumers' title='Consumers' hasBullet={true} /> */}
        <AsideMenuItem to='/crud-operations/pages/groups' title='Groups' hasBullet={true} />


      </AsideMenuItemWithSub>

    </>
  )
}
