import React, { useMemo, useState, useCallback, FC, useEffect } from 'react'
import { useTable, ColumnInstance, Row, useExpanded, usePagination, useRowSelect } from 'react-table'
import { CustomHeaderColumn } from './CustomHeaderColumn'
import CustomRow from './CustomRow'
import { KTCardBody } from '../../../../../_metronic/helpers'
import { UsersListPagination } from '../UsersListPagination'
import { UsersListLoading } from '../UsersListLoading'
import { SmallPagination } from '../SmallPagination'
import { RiErrorWarningFill } from '@react-icons/all-files/ri/RiErrorWarningFill';

export interface IMainTableProps {
  columns: any,
  data: any,
  isLoading: boolean,
  context: {
    useQueryResponseData: any, useQueryResponseLoading: any, useQueryResponsePagination: any, useQueryRequest: any
  },
  renderRowSubComponent?: any;
  smallPaggination?: boolean,
  collength?: number,
  multiSelection?: boolean,
  getSelectedFlatRows?: (e: any) => void
}


const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef<any>()
    const resolvedRef: any = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <div className='form-check form-check-custom form-check-sm text-center'>
        <input className='form-check-input' type="checkbox" ref={resolvedRef} {...rest} />
      </div>
    )
  }
)


export const MainTable: React.FunctionComponent<IMainTableProps> = (props) => {

  const { useQueryResponsePagination, useQueryRequest } = props.context;

  const { state: componentState } = useQueryRequest();
  const pagination: any = useQueryResponsePagination();

  const { columns, data, isLoading, renderRowSubComponent, collength, multiSelection, getSelectedFlatRows } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    headers,
    rows,
    page,
    selectedFlatRows,
    setPageSize,
    //resetRowSelection,
    state: { pageIndex, pageSize, selectedRowIds },
    visibleColumns,
    setHiddenColumns

  }: any = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns: columns.filter((col: any) => col.show === false).map((col: any) => col.id || col.accessor) as any,
    }
  },
    usePagination,
    useRowSelect,

    hooks => {
      multiSelection && hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
            <th >
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </th>
          ),
          Cell: ({ row }: any) => (
            <IndeterminateCheckbox {...row?.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ])
    }
  );

  useEffect(() => {
    setPageSize(pagination?.items_per_page)
    return () => { }
  }, [pagination.items_per_page])

  // useEffect(() => {
  //   if (!multiSelection) setHiddenColumns(['selection'])
  //   return () => {}
  // }, [multiSelection])


  // console.log({ selectedFlatRows });

  useEffect(() => {
    if (data && data) {
      multiSelection && getSelectedFlatRows && getSelectedFlatRows(selectedFlatRows)
    }
    return () => { }
  }, [data, selectedFlatRows])



  const [selectedRow, setselectedRow] = useState<any>(null);
  const { state, updateState } = useQueryRequest();
  const getRowdata = useCallback((data: any) => setselectedRow(data), []);

  return (
    <KTCardBody className='py-2 px-0 position-relative'>
      <div className='table-responsive mh-500px'>
        <table
          id='kt_table_users'
          className='table table-hover align-middle table-row-dashed fs-6 dataTable no-footer table-bordered border-2 border-primary'
          {...getTableProps()}
        >
          <thead className='bg-light'>
            {/* <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light ronded'> */}
            {
              headerGroups.map((headerGroup: any) => (
                <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light ronded' {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: ColumnInstance) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              ))
              // headers.map((column: ColumnInstance<any>) => (
              //   <CustomHeaderColumn key={column.id} column={column} />
              // ))
            }
            {/* </tr> */}
          </thead>
          <tbody className='' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i: any) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} getSelectedRow={(e: any) => getRowdata(e)} selectedRow={selectedRow} />
              })
            ) : (
              <tr>
                <td colSpan={collength || columns?.length} >
                  <div className='d-flex text-center w-100 align-content-center justify-content-center '>
                    {componentState?.filter && Object.values(componentState?.filter).length > 0 ? 'No matching records found' : <>
                      <div className="alert alert-info w-100 border-0">
                        <RiErrorWarningFill className='text-info' /> Please select appropriate filters
                      </div>
                    </>}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {props.smallPaggination ? <SmallPagination {...props.context} /> : <UsersListPagination {...props.context} />}
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
};





