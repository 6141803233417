import clsx from 'clsx'
import React, { FC, useState, useRef, useEffect } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  QuickLinks,
  Search,
  ThemeModeSwitcher,
} from '../../../partials'
import { useLayout } from '../../core'

import Select, {
  components,
  OptionProps,
  Options,
  SingleValueProps,
  OnChangeValue,
  OptionsOrGroups,
  GroupBase,
  Props,
  SingleValue

} from "react-select";
import StateFilter from '../../../../app/modules/Commons/Filters/StateFilter'
import { useAuth } from '../../../../app/modules/auth'
import { Link } from 'react-router-dom'
import { useMainAppContext } from '../../MasterLayout'
import { BiMessageDetail } from 'react-icons/bi'
import CustomDropdownComponent from '../../../../app/modules/Commons/Components/CustomDropdownComponent'
import { RiMessage2Fill } from '@react-icons/all-files/ri/RiMessage2Fill'
import { AttendanceToggle } from '../../../../app/modules/PunchInPunchOut/AttendanceToggle'
import NavSwicher from '../../../../app/modules/Commons/Components/NavSwicher'
import { v4 as uuidv4 } from 'uuid'
import { apiData } from './request'
import { updateStates } from '../../../../app/modules/CRUDOperations/Components/StatesManagement/Context/core/_requests'
import { RiTimerFill } from 'react-icons/ri'
import { FaClock, FaUserClock } from 'react-icons/fa'
import { getUserById } from '../../../../app/modules/auth/core/_requests'
import ProfileImageCOmponent from '../../../../app/modules/Commons/Components/ProfileImageCOmponent'

interface OptionType {
  label: string;
  value: string;
}

const options: Options<OptionType> = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" }
];

const navOptions: any = [
  { title: 'Punch-In', value: 'PUNCH_IN' },
  { title: 'Punch-Out', value: 'PUNCH_OUT' },
]
// PUNCH_IN: 'Punch-In',
// PUNCH_OUT: 'Punch-Out',

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-30px',
  toolbarButtonIconSizeClass = 'svg-icon-1'


const fomatHoursAndMintues = (num: Number) => {
  return "0".concat(num.toString()).slice(-2)
}

const CustomeTopbar: FC = () => {

  const { config } = useLayout()
  const { currentUser, setCurrentUser }: any = useAuth();
  const { state: globalState, updateState: updateGlobalState } = useMainAppContext();
  const [messagesIconState, setMessagesIconState] = React.useState<any>(0)
  const [profileimageUrl, setprofileimageUrl] = useState<string>('');
  const [isLoading, setisLoading] = useState(false)
  const [workingHours, setWorkingHours] = useState<{ hours: string, minutes: string }>({ hours: "00", minutes: "00" })

  const [psToken, setpsToken] = useState<any>(undefined)

  const [updateWorkStatus, setupdateWorkStatus] = useState<any>(undefined)

  // console.log({ currentUser, workStatus: currentUser?.workStatus, updateWorkStatus })



  useEffect(() => {

    let intervalId = 0;
    const { workStatus, totalWorkingHours } = currentUser

    if (workStatus === "PUNCH_IN" && totalWorkingHours) {

      window.clearInterval(intervalId)

      const [hours, minutes, seconds] = totalWorkingHours.split(".")[0].split(":")
      const workingHours = new Date()
      workingHours.setHours(hours);
      workingHours.setMinutes(minutes);
      workingHours.setSeconds(seconds);

      setWorkingHours({
        hours: fomatHoursAndMintues(workingHours.getHours()),
        minutes: fomatHoursAndMintues(workingHours.getMinutes())
      })

      intervalId = window.setInterval(() => {
        workingHours.setSeconds(workingHours.getSeconds() + 1)
        setWorkingHours({
          hours: fomatHoursAndMintues(workingHours.getHours()),
          minutes: fomatHoursAndMintues(workingHours.getMinutes())
        })
      }, 1000)
    }
    else if (workStatus === "PUNCH_OUT" && totalWorkingHours) {
      const [hours, minutes] = totalWorkingHours.split(".")[0].split(":")

      setWorkingHours({
        hours: hours,
        minutes: minutes
      })
    }

    return () => {
      window.clearInterval(intervalId)
    }

  }, [currentUser])


  useEffect(() => {
    setupdateWorkStatus(currentUser?.workStatus)

    // setpsToken(currentUser?.ps_token)
    return () => { }
  }, [currentUser?.workStatus])



  useEffect(() => {
    if (currentUser?.workStatus && updateWorkStatus) {
      if (currentUser?.workStatus === "PUNCH_IN" && updateWorkStatus === "PUNCH_OUT") {
        updateToken()
      }
      else if (currentUser?.workStatus === "PUNCH_OUT" && (updateWorkStatus === undefined || updateWorkStatus === "PUNCH_IN")) {
        updateToken()
      }
    }

    return () => { }
  }, [updateWorkStatus])

  const updateToken = async () => {

    setisLoading(true)
    console.log({ psToken: currentUser?.ps_token, before: psToken });

    let newToken = uuidv4();
    let postData = {
      ps_token: updateWorkStatus === "PUNCH_IN" ? newToken : currentUser?.ps_token,
      internet_status: "ONLINE",
      work_status: updateWorkStatus
    }

    // fetch api here
    let apiResp: any = await apiData(postData);
    if (apiResp && apiResp?.workStatus) {
      console.log({ apiResp });
      setupdateWorkStatus(updateWorkStatus)
    }
    const getUserStatus = await getUserById(currentUser.userId)

    if (updateWorkStatus === "PUNCH_IN") {
      setCurrentUser({ ...currentUser, ps_token: newToken, workStatus: updateWorkStatus, loginAt: getUserStatus?.login_at, last_logout_at: getUserStatus?.last_logout_at, totalWorkingHours: getUserStatus?.total_working_time })
    }
    else {
      setCurrentUser({ ...currentUser, ps_token: undefined, workStatus: updateWorkStatus, loginAt: getUserStatus?.login_at, last_logout_at: getUserStatus?.last_logout_at, totalWorkingHours: getUserStatus?.total_working_time })
    }

    setisLoading(false)

  }




  // useEffect(() => {
  //   setCurrentUser({ ...currentUser, workStatus: updateWorkStatus && updateWorkStatus || 'PUNCH_OUT' })
  //   return () => { }
  // }, [updateWorkStatus])



  React.useEffect(() => {
    if (currentUser?.profile_img_url) {
      setprofileimageUrl(currentUser?.profile_img_url)
    }
  }, [currentUser?.profile_img_url])

  React.useEffect(() => {
    if (globalState?.new_messages && Object.keys(globalState?.new_messages).length > 0) {
      const notificationCounts = Object.values(globalState?.new_messages).reduce((a: any, b: any) => a + b, 0);
      setMessagesIconState(notificationCounts)
    }
    else {
      setMessagesIconState(0)
    }
  }, [globalState?.new_messages])

  const setWorkStatus = (status: any) => {
    setupdateWorkStatus(status)
  }



  // console.log({ globalState: globalState, messagesIconState });
  const fixRoles = ["COMMERCIAL_MANAGER", "DISTRICT_BUSSINESS_MANAGER", "DATA_ANALYST_AND_COORDINATOR"]


  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>


      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* <StateFilter onChange={(e) => console.log(e)} /> */}
        {/* <Search /> */}
        <div className='me-2 border border-secondary p-2 rounded'>
          <span className='fs-3 fw-bold cursor-pointer' data-bs-toggle='tooltip' title='Working Hours'>
            <FaClock className='me-2 text-muted' size={24} />{Number(workingHours.hours) === 0 ? <span>{workingHours.hours}:{workingHours.minutes} <span className='text-muted fs-normal fs-4'>mins</span></span> : <span> {workingHours.hours}:{workingHours.minutes} <span className='text-muted fw-normal fs-4'> hrs</span></span>}
          </span>
        </div>

        {/* {<NavSwicher isLoading={isLoading} hideAll switchs={navOptions} name='AttendancePunchInOut' value={updateWorkStatus} selected={setWorkStatus} />} */}
        <div className='nav-group nav-group-outline nav-group-fluid'>
          <label key={'label-punch-in'}>
            <input
              type='radio'
              className='btn-check'
              name={'punch_in'}
              checked={updateWorkStatus == "PUNCH_IN"}
              onChange={(e: any) => setWorkStatus("PUNCH_IN")}
            //disabled={isLoading || false}
            />
            <span className='btn py-2 px-4 btn-color-muted btn-active btn-active-success text-nowrap fs-5'>
              Punch-In
            </span>
          </label>
          <label key={'label-punch-out'}>
            <input
              type='radio'
              className='btn-check'
              name={'punch_out'}
              checked={updateWorkStatus == "PUNCH_OUT"}
              onChange={(e: any) => setWorkStatus("PUNCH_OUT")}
            //disabled={isLoading || false}
            />
            <span className='btn py-2 px-4 btn-color-muted btn-active btn-active-danger text-nowrap fs-5'>
              Punch-Out
            </span>
          </label>
        </div>
      </div>

      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>

        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen022.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <AttendanceToggle />

      </div> */}


      {/* begin::Chats */}

      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <Link to="/chats/pages/group-chat" >
          {/* begin::Menu wrapper */}

          {/* <KTSVG
            path='/media/icons/duotune/communication/com012.svg'
            className={toolbarButtonIconSizeClass}
          /> */}

          {/* <MdMessage className='text-success position-relative' size={24} >
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              99+
              <span className="visually-hidden">unread messages</span>
            </span>
          </MdMessage> */}
          {messagesIconState && messagesIconState > 0 ?
            <button type="button" className="btn btn-light position-relative p-1 rounded-0 mx-3">
              <RiMessage2Fill className='text-success position-relative' size={24} />
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">
                {messagesIconState}
                <span className="visually-hidden">unread messages</span>
              </span>
            </button> : <BiMessageDetail className='text-muted' size={24} />}

        </Link>
        {/* end::Menu wrapper */}
      </div>

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          {currentUser && currentUser?.profile_img_url ? <ProfileImageCOmponent urlProps={currentUser && currentUser?.profile_img_url} /> : <img
            src={toAbsoluteUrl('/media/svg/avatars/blank.svg')}
            className='w-40px h-75 rounded '
            alt=''
          />}
          {/* <img src={toAbsoluteUrl(currentUser && currentUser?.profile_img_url || '')} alt='metronic' onError={(e) => setprofileimageUrl('/media/svg/avatars/blank.svg')} /> */}

          {/* <div className='d-flex fw-bolder'>
            <span className='symbol symbol-25px me-5 ' >
              <img src={toAbsoluteUrl(currentUser && currentUser?.profile_img_url || '')} alt='metronic' />
            </span>
            <div >{currentUser?.name}</div>
          </div> */}
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

    </div>
  )
}

export { CustomeTopbar }
