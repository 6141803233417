/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
// import { useQueryResponseLoading, useQueryResponsePagination  } from '../../../core/QueryResponseProvider'
// import { useQueryRequest } from '../../../core/QueryRequestProvider'
import { useState } from 'react';
import { useEffect } from 'react';
import { PaginationState } from '../../../../_metronic/helpers';
import { BiMenu } from 'react-icons/bi'

type IpagginationProps = {
  useQueryResponseLoading: () => boolean,
  useQueryResponsePagination: () => PaginationState,
  useQueryRequest: () => {
    state: any,
    updateState: any
  }

}

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const SmallPagination = ({ useQueryResponseLoading, useQueryResponsePagination, useQueryRequest }: IpagginationProps) => {

  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const { state, updateState } = useQueryRequest()

  // console.log({ pagination });


  const [currentRange, setcurrentRange] = useState<number[]>([1, 2, 3, 4, 5]);

  useEffect(() => {
    renderPagination(Number(pagination.page), Number(pagination.total), Number(pagination.items_per_page))
    return () => { }
  }, [pagination.page,pagination.total,pagination.items_per_page]);

  const renderPagination = (activepgNo: number, total: number, pageSize: number) => {

    //console.log("activepgNo", activepgNo, "total", total);
    const totalPages = Number(total); //Number((total / pageSize).toFixed(0));
    //console.log("total pages ", totalPages);

    const range = (start: number, end: number) => Array.from({ length: (end - start) }, (v, k) => k + start);

    if (totalPages >= 5 && activepgNo >= 5) {
      var pagginatiuonRange: any = getPagginattionRange(activepgNo);
      //console.log("Range", pagginatiuonRange);
      if (activepgNo == pagginatiuonRange[0]) {
        if (activepgNo != currentRange[currentRange.length - 1]) {
          pagginatiuonRange = getPagginattionRange(activepgNo - 1);
        }
        else pagginatiuonRange = pagginatiuonRange;

      }
      else if (activepgNo == pagginatiuonRange[pagginatiuonRange.length - 1]) {
        ///console.log("yes its 5 ");
        pagginatiuonRange = getPagginattionRange(activepgNo + 1);
      }

      //console.log("pagginatiuonRange", pagginatiuonRange);
      pagginatiuonRange[0] = pagginatiuonRange[0] < 1 ? 1 : pagginatiuonRange[0];
      pagginatiuonRange = range(pagginatiuonRange[0], (pagginatiuonRange[1] + 1))
    }
    else {
      pagginatiuonRange = range(1, 6);
    }

    setcurrentRange(pagginatiuonRange)

  }

  const getPagginattionRange = (activepgNo: number) => {

    const isNotFloat = (n: number) => Number(n) === n && n % 1 !== 0;

    const devide = parseInt((activepgNo / 4).toString());
    //console.log(devide);

    const startNum = (devide * 4) + 1;
    const range_array = isNotFloat(activepgNo / 4) ? [startNum, startNum + 4] : [startNum - 4, startNum];
    //console.log('range', range_array);
    return range_array;
  }


  // useEffect(() => {

  //   return () => {}
  // }, [pagination]);


  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }
    updateState({ page, items_per_page: pagination.items_per_page || 10 })
  }


  const updateRows = (rows: 10 | 30 | 50 | 100) => {
    if (!rows || isLoading || pagination.items_per_page === rows) {
      return
    }
    updateState({ ...state, page: 1, items_per_page: rows || 10 })
  }

  // console.log("pagination", pagination)

  return (
    pagination && pagination.total &&
    <div className='d-flex justify-content-between pt-10 px-3 align-items-center'>
      <div className='d-flex  align-items-center '>
        <div className='d-flex align-items-center bg-light'>
          <BiMenu size={24} />
          <select className='form-select form-select-sm form-select-solid rounded-0 w-auto' value={pagination.items_per_page} onChange={(e: any) => updateRows(e.target.value)} >
            <option value={"10"}>10</option>
            <option value={"30"}>30</option>
            <option value={"50"}>50</option>
            <option value={"100"}>100</option>
          </select>
        </div>
        <div className='text-center px-5 fw-bolder text-muted'>
          <div> Showing {pagination && pagination?.from} to {pagination?.to} of {pagination?.total_records}  </div>
        </div>
      </div>

      <div className=''>
        <div id='kt_table_users_paginate' >
          <ul className='pagination'>
            <li
              key={"firstpage"}
              className={clsx('page-item btn btn-outline btn-outline-dashed btn-outline-primary p-0', {
                disabled: isLoading,
                previous: true,
                // next: link.label === 'Next',
              })}
            >
              <a
                className={clsx('page-link ', {
                  //'page-text': link.label === 'Previous' || link.label === 'Next',
                  //'me-5': link.label === 'Previous',
                })}
                onClick={() => updatePage(1)}
                style={{ cursor: 'pointer' }}
              >
                {mappedLabel('1')}
              </a>
            </li>
            <li
              key={"prevpage"}
              className={clsx('page-item ', {
                disabled: isLoading,
                previous: true,
                // next: link.label === 'Next',
              })}
            >
              <a
                className={clsx('page-link ', {
                  //'page-text': link.label === 'Previous' || link.label === 'Next',
                  //'me-5': link.label === 'Previous',
                })}
                onClick={() => updatePage(Number(pagination.page) - 1)}
                style={{ cursor: 'pointer' }}
              >
                {mappedLabel('<')}
              </a>
            </li>
            {/* {renderPagination(Number(pagination.page), Number(pagination.total), Number(pagination.items_per_page))} */}
            {
              pagination && pagination.total && currentRange
                .map((num: any, indx: number) => {
                  return { num, label: mappedLabel((num).toString()) }
                })
                .map((num: any, indx: number) => {
                  return pagination && pagination.total && num.num <= pagination.total &&
                    <li
                      key={num.num}
                      className={clsx('page-item ', {
                        active: pagination.page == num.num,
                        disabled: isLoading,
                        'bg-light-primary': pagination.page != num.num
                        // previous: link.label === 'Previous',
                        // next: link.label === 'Next',
                      })}
                    >
                      <a
                        className={clsx('page-link', {
                          //'page-text': link.label === 'Previous' || link.label === 'Next',
                          //'me-5': link.label === 'Previous',
                        })}
                        onClick={() => updatePage(Number(num.num))}
                        style={{ cursor: 'pointer' }}
                      >
                        {mappedLabel((Number(num.num)).toString())}
                      </a>
                    </li>
                })
            }
            {Number(pagination?.page) != Number(pagination?.last_page) ? <>
              <li
                key={"nextpage"}
                className={clsx('page-item ', {
                  disabled: isLoading,
                  previous: true,
                  // next: link.label === 'Next',
                })}
              >
                <a
                  className={clsx('page-link', {
                    //'page-text': link.label === 'Previous' || link.label === 'Next',
                    //'me-5': link.label === 'Previous',
                  })}
                  onClick={() => updatePage(Number(pagination.page) + 1)}
                  style={{ cursor: 'pointer' }}
                >
                  {mappedLabel('>')}
                </a>
              </li>
              <li
                key={"lastpage"}
                className={clsx('page-item btn btn-outline btn-outline-dashed btn-outline-primary p-0', {
                  disabled: isLoading,
                  // previous: link.label === 'Previous',
                  next: true,
                })}
              >
                <a
                  className={clsx('page-link ', {
                    //'page-text': link.label === 'Previous' || link.label === 'Next',
                    //'me-5': link.label === 'Previous',
                  })}
                  onClick={() => updatePage(Number(pagination.total))}
                  style={{ cursor: 'pointer' }}
                >
                  {mappedLabel((pagination?.last_page || '>>').toString())}
                </a>
              </li>
            </> : <></>}

            {/* {pagination.links
              ?.map((link) => {
                return { ...link, label: mappedLabel(link.label) }
              })
              .map((link) => (
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    active: pagination.page === link.page,
                    disabled: isLoading,
                    previous: link.label === 'Previous',
                    next: link.label === 'Next',
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': link.label === 'Previous' || link.label === 'Next',
                      'me-5': link.label === 'Previous',
                    })}
                    onClick={() => updatePage(link.page)}
                    style={{ cursor: 'pointer' }}
                  >
                    {mappedLabel(link.label)}
                  </a>
                </li>
              ))} */}
          </ul>
        </div>
      </div>
    </div> || <></>
  )
}

export { SmallPagination }
