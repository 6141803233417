import { FC, createContext, useContext, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { AppContext, AppContextInterface } from '../../app/Context/appContext'
import { MenuComponent } from '../assets/ts/components'
import {
  ThemeModeProvider
} from '../partials'
import { Content } from './components/Content'
import { ScrollTop } from './components/ScrollTop'
import { AsideDefault } from './components/aside/AsideDefault'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { PageDataProvider } from './core'


const MainAppContext = createContext<any>(null)

const MainAppContextProvider: FC<any> = ({ children }) => {

  const [state, setState] = useState<any>({})

  // const { socket, setMembers, members, setCurrentRoom, setRooms, privateMemberMsg, rooms, setPrivateMemberMsg, currentRoom } = useContext(AppContext) as AppContextInterface;

  // socket.off("notifications").on("notifications", (room: any) => {
  //   // console.log({ currentRoom, room, state });

  //   let items = state?.new_messages || {};
  //   // console.log({ items });

  //   let previousCount = Object.keys(items).includes(room) ? items[room] : 0;
  //   // console.log({ previousCount });
  //   items = {
  //     ...items,
  //     [room]: previousCount + 1
  //   }

  //   // console.log({ items, previousCount });
  //   setState({
  //     ...state,
  //     new_messages: items
  //   });

  // });

  // console.log({ MainAppContext: state });

  const updateState = (updates: any) => {
    const updatedState = { ...state, ...updates }
    setState(updatedState)
  }

  return (
    <MainAppContext.Provider value={{ state, updateState }}>
      {children}
    </MainAppContext.Provider>
  )
}

export const useMainAppContext = () => useContext(MainAppContext)

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <MainAppContextProvider>
      <PageDataProvider>
        <ThemeModeProvider>
          <div className='page d-flex flex-row flex-column-fluid'>
            <AsideDefault />
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              <HeaderWrapper />
              {/* <div className='alert position-fixed w-100 alert-danger bg-danger text-white rounded-0 close' style={{ zIndex: 5 }}>
              <strong>Holy guacamole!</strong> You should check in on some of those fields below.
              <button type="button" className="btn-close text-white" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> */}
              <div id='kt_content' className='content d-flex flex-column flex-column-fluid py-3'>
                {/* <Toolbar /> */}
                {/* <div className='post d-flex flex-column-fluid bg-white'>
                  <span className='p-5'>
                    Filters:
                  </span>
                </div> */}
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                  <Content>
                    <Outlet />
                  </Content>

                </div>
              </div>
              {/* <Footer /> */}
            </div>
          </div>

          {/* begin:: Drawers */}
          {/* <ActivityDrawer /> */}
          {/* <RightToolbar /> */}
          {/* <DrawerMessenger /> */}
          {/* end:: Drawers */}

          {/* Reconection Drawer */}
          {/* <ReconnectionModel /> */}
          {/* Reconection Drawer End */}

          {/* begin:: Modals */}
          {/* <InviteUsers />
          <UpgradePlan /> */}
          {/* end:: Modals */}
          <ScrollTop />
        </ThemeModeProvider>
      </PageDataProvider>
    </MainAppContextProvider>
  )
}

export { MasterLayout }

