import {FC, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../core'
import {DrawerComponent} from '../../assets/ts/components'
import {WithChildren} from '../../helpers'
import Cookies from 'universal-cookie';

const Content: FC<WithChildren> = ({children}) => {
  const {classes} = useLayout()
  const location = useLocation()
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const cookies = new Cookies();
  

  useEffect(() => {
    cookies.set('kt-last-url',  pathname + search, { path: '/' });
    return () => {}
  }, [pathname,search])
  

  useEffect(() => {
    DrawerComponent.hideAll()
    
  }, [location])

  return (
    <div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>
      {children}
    </div>
  )
}

export {Content}
