import React, { useEffect, useState, useMemo, useRef, memo, useReducer, useCallback } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

type Props = {
    size?: any,
    urlProps?: any
}

const ProfileImageCOmponent: React.FC<Props> = ({ size = '120', urlProps }) => {


    const baseUrl = process.env.REACT_APP_URL_PROFILE_IMAGE;

    const [url, seturl] = useState('')

    useEffect(() => {
        seturl(baseUrl + "/c" + size + "x" + size + "/" + urlProps)
        return () => { }
    }, [size])

    useEffect(() => {
        seturl(baseUrl + "/c" + size + "x" + size + "/" + urlProps)
        return () => { }
    }, [urlProps])

    //console.log({url});

    // console.log({urlProps});
    
    

    return <>
        <img
            src={toAbsoluteUrl(url)}
            className='w-40px h-75 rounded'
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = toAbsoluteUrl('/media/svg/avatars/blank.svg');
            }}
            alt=''
        />
    </>
}

export default ProfileImageCOmponent