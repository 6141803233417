import React, { useEffect, useState, useMemo, useRef, memo, useReducer, useCallback } from 'react';
import { toAbsoluteUrl } from '../../../../../../../../../../_metronic/helpers';
import ProfileImageCOmponent from '../../../../../../../../Commons/Components/ProfileImageCOmponent'
import { RiPhoneFill } from 'react-icons/ri';

type Props = {
    rowData: any
}

const MarketersInfoColumn: React.FC<Props> = ({ rowData }) => {
    return <>
        <div className='d-flex align-items-center'>
            {
                rowData?.employee_profile_image_url ? <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label bg-light'>
                        <ProfileImageCOmponent urlProps={rowData?.employee_profile_image_url} />
                    </span>
                </div> : <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label bg-light'><img
                        src={toAbsoluteUrl('/media/svg/avatars/blank.svg')}
                        className='w-40px h-75 rounded '
                        alt=''
                    />   </span>
                </div>
            }

            <div className='d-flex justify-content-start flex-column'>
                <span className='text-dark fw-bold mb-1 fs-6' data-bs-toggle='tooltip' title='Employee Name'>
                    {(rowData?.employee_name) || '-'}
                </span>
                {/* <span className='text-muted fw-semibold text-muted d-block fs-7' data-bs-toggle='tooltip' title='Employee Phone'>
                    {(rowData?.employee_phone || "-")}
                </span> */}

                <span className='text-muted fw-semibold text-muted d-block fs-7' data-bs-toggle='tooltip' title='Employee Phone'>
                    <span className='badge badge-light'>   <RiPhoneFill  size={12} /> </span><span className='text-dark'>{(rowData?.employee_phone || "-")}</span>
                </span>
            </div>
        </div>
    </>
}

export default MarketersInfoColumn