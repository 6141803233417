import React, { useEffect, useState, useMemo, useRef, memo, useReducer, useCallback } from 'react';
import { ReactElementType } from 'react-window';
import { ReactNode, ReactPropTypes } from 'react'
import { Modal } from 'react-bootstrap';

type Props = {
    isOpen?: boolean,
    onHide?: () => void,
    title?: string,
    children?: ReactNode
}

const ModelComponent: React.FC<Props> = ({ isOpen, onHide, title, children }) => {

    const hndleOnHide = () => {
        onHide && onHide();
    }

    return <>
        <Modal size='lg' show={isOpen || false} onHide={hndleOnHide} backdrop="static" keyboard={false} centered>
            <Modal.Header className='py-4' closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    </>
}

export default ModelComponent