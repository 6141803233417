import * as React from 'react';
import { KTSVG } from '../../../../../../../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { ReconnectionModel as RMModel } from '../../../../../../../../Commons/Models/ReconnectionModel/ReconnectionModel';
import { DisconnectionReportState } from '../../..';
import { BiUnlink } from 'react-icons/bi';

type IReconnectionModelProps = {
    isDisconnected?: string;
    rowData?: any
};


type IModelProps = {

}


const ReconnectionModel = ({ isDisconnected, rowData }: IReconnectionModelProps) => {

    const { state, updateState } = DisconnectionReportState();
    const { order_status } = rowData;

    const openReconnectionForm = () => {
        updateState({
            other: {
                openReconnectionModal: true,
                data: rowData
            }
        })
    }

    // console.log("render model", isDisconnected, rowData);
    return <>
        {
            order_status == "DONE" ? <span onClick={openReconnectionForm} className='cursor-pointer'>
                <BiUnlink size={20} className="text-success" />
            </span> : <span className=''>
                <BiUnlink size={20} className="text-muted"/>
            </span>
        }

    </>
}

export default ReconnectionModel;
