import axios, { AxiosResponse } from 'axios'
import { URL_ORDERS_CONSUMERS_LIST } from '../../../../../../../apis'

const fetchDashboardReport = (query: string): Promise<any> => {
  return axios
    .post(`${URL_ORDERS_CONSUMERS_LIST}?${query}`, { "name": "DISCONNECTION" })
    .then((d: AxiosResponse<any>) => d.data.data)
    .catch((e: any) => e?.response?.data)
}

const createReaconnectionOrder = (postdata: any) => {
  const url = process.env.REACT_APP_API_URL_BE_SERVICE + '/api/v1/orders';
  return axios
    .post(`${url}`, postdata)
    .then((d: AxiosResponse<any>) => d.data)
    .catch((e: any) => e?.response?.data)
}

export {
  fetchDashboardReport,
  createReaconnectionOrder
}
