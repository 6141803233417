import * as React from 'react';
import ReconnectionForm from '../modules/Commons/Forms/ReconnectionForm';

export interface IGolbalTestFormProps {
}

export function GolbalTestForm (props: IGolbalTestFormProps) {
  return (
    <div className='pt-16'>
      <ReconnectionForm />
    </div>
  );
}
