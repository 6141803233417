import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./reducers/userSlice";
import appApi from "./reducers/apiSlice";

// persist our store
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import chatsApiSlice from "./reducers/chatsApiSlice";

// reducers
const reducer = combineReducers({
    user: userSlice,
    [appApi.reducerPath]: appApi.reducer,
    [chatsApiSlice.reducerPath]: chatsApiSlice.reducer
});

const persistConfig = {
    key: "root",
    storage,
    blackList: [appApi.reducerPath, chatsApiSlice.reducerPath],
};

// persist our store

const persistedReducer = persistReducer(persistConfig, reducer);

// creating the store

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk, appApi.middleware, chatsApiSlice.middleware],
});

export default store;
