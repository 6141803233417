import { FaSignature } from 'react-icons/fa'
import Viewer from 'react-viewer';
import axios, { AxiosResponse } from 'axios'
import React, { useState, useEffect } from 'react'
import { BiImages } from 'react-icons/bi';


type ActionsColumnProps = { rowData: any }; /* could also use interface */

const ActionsColumn = ({ rowData }: ActionsColumnProps) => {

    const [visible, setVisible] = useState(false);
    const [images, setImages] = useState<any>([{
        src: "",
        alt: ""
    }]);


    const viewImage = () => {
        // fetchImagesFromEventID(rowData.event_id)
        setVisible(!visible)
    }

    const setImagesToView = (imageData: any) => {
        setVisible(true);
        if (imageData && imageData) {
            setImages(imageData.map((data: any) => {
                return {
                    src: data,
                    alt: ""
                }
            }));
        }
    }

    return <>
        {visible && < Viewer
            visible={visible}
            onClose={viewImage}
            images={images}
        />}
        {rowData && <div className="d-flex">
            {rowData && rowData.images && rowData.images.length > 0 && <div className='btn btn-light-primary px-2 py-1 mx-2 rounded-1 '> <BiImages size={16} onClick={() => setImagesToView(rowData.images)} /></div>}
            {rowData && rowData.signature_url && <div className='btn btn-light-primary px-2 py-1 mx-2 rounded-1'> <FaSignature size={16} onClick={() => setImagesToView([rowData.signature_url])} /></div>}
            {/* <div className='btn btn-primary px-2 py-1 mx-2 rounded-1 text-white'> <GrContactInfo size={16} color={'#fff'} /></div> */}
        </div>}
    </>
}

export default ActionsColumn;
