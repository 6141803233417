import { useState } from 'react';
import Select, {
    components,
    OptionProps,
    Options,
    SingleValueProps,
    OnChangeValue,
    OptionsOrGroups,
    GroupBase,
    Props,
    SingleValue
} from "react-select";

import { KTSVG } from '../../../../_metronic/helpers';
import MainReconnectionForm from '../../Customers/Actions/Disconnection/Report/Components/ReconnectionForm';

import axios, { AxiosResponse } from 'axios'
import { QueryClient, QueryClientProvider, useMutation, useQuery, useQueryClient } from 'react-query';
import { env } from 'process';
import { PulseLoader } from 'react-spinners';
import { ReconnectionReportState } from '../../Customers/Actions/Reconnection/Report/Context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


type ReconnectionFormProps = {
    message?: string;
};


type OptionType = {
    label: string;
    value: string;
}

const ReconnectionForm = ({ }: ReconnectionFormProps) => {

    const { state, updateState } = ReconnectionReportState();

    const createReaconnectionOrder = (postdata: any): Promise<any | undefined> => {
        //console.log("query", postdata);
        const url = process.env.REACT_APP_API_URL_BE_SERVICE + '/api/v1/orders';
        return axios
            .post(`${url}`, postdata)
            .then((d: AxiosResponse<any>) => d.data)
    }

    const notify = (msg:string) => toast(msg);

    const { isLoading, error, data, mutate, isError, isSuccess, status } = useMutation(createReaconnectionOrder, {
        onError: (error, variables, context) => {
            // An error happened!
            //console.log({ onerror: { error, variables, context } });
        },
        onSuccess: (data, variables, context) => {
            // Boom baby!
            //console.log({ onSuccess: { data, variables, context } })
            var apimsg = data.message;
            notify(apimsg)
        },
        onSettled: (data, error, variables, context) => {
            // Error or success... doesn't matter!
            //console.log({ onSettled: { data, error, variables, context } })
        },
    });

    const handleSubmitForm = async (e: any) => {
        try {
            mutate({
                ...e,
                "order_type": "EMPLOYEE_GENERATED",
                "order_status": "ISSUED",
                "order_name": "reconnection",
                "order_by": "96",
            });
        }
        catch (err) {
            //console.log(err);
        }
    }


    return (
        <div>
            <div className='row justify-content-center '>
                <div className={`card card-xxl-stretch mb-xl-8 w-600px`}>
                    <div className='card-header border-0 pt-5 '>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bold fs-3 mb-1'>Reconnection Form</span>
                            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
                        </h3>
                    </div>
                    <div className='card-body pt-0'>
                        <MainReconnectionForm onFormSubmit={(e: any) => handleSubmitForm(e)} isError={isSuccess && data.code != 200} errorMsg={isSuccess && data.message} />
                    </div>
                </div>
            </div>
            <ToastContainer hideProgressBar={true} pauseOnHover />
        </div>
    )
};

export default ReconnectionForm