import axios, { AxiosResponse } from 'axios'
import { getAuth, setupAxios } from './AuthHelpers'
import { AuthModel, UserModel } from './_models'
import { ID, Response } from '../../../../_metronic/helpers'
import { URL_EMPLOYEE_ATTENDANCE_STATUS_URL, URL_USER_PROFILE_BY_TOKEN } from '../../../apis'

const API_URL = process.env.REACT_APP_API_URL_AUTH_SERVICE
const PASSWORD_URL = process.env.REACT_APP_API_URL_AUTH_SERVICE_NEW


export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${PASSWORD_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${PASSWORD_URL}/forgot-password`
export const RESET_PASSWORD_URL = `${PASSWORD_URL}/reset-password`
export const CHANGE_PASSWORD_URL = `${PASSWORD_URL}/change-password`
export const getUserDataByToken = `${PASSWORD_URL}/profile`

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
    email: null
  }).then((d: any) => d.data)
    .catch((e: any) => e.response?.data)
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(username: string) {
  return axios.post<AxiosResponse>(REQUEST_PASSWORD_URL, {
    username,
  })
}

export function requestPasswordReset(password: string, token: string) {
  return axios.post<AxiosResponse>(`${RESET_PASSWORD_URL}?token=${token}`, {
    password
  })
}

export function changePassword(new_password: string, confirm_password: string) {
  return axios.post<AxiosResponse>(`${CHANGE_PASSWORD_URL}`, {
    new_password, confirm_password
  })
}

export const getUserById = (id: string) => {
  return axios
    .get(URL_EMPLOYEE_ATTENDANCE_STATUS_URL(id))
    .then((response: AxiosResponse<Response<any>>) => response)
    .then((response: Response<any>) => response.data.data)
}

export function getUserByToken(token?: string) {

  const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
  const key = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  const auth = getAuth()
  return axios.get<any>(getUserDataByToken, {
    'headers': {
      'Authorization': `Bearer ${key}`
    }
  });
}
