// @ts-nocheck

import clsx from 'clsx'
import { FC, useMemo, memo } from 'react'
import { Row } from 'react-table'

import { useState } from 'react';

type Props = {
  row: Row<any>,
  getSelectedRow: any,
  selectedRow: any,
  visibleColumns?: any,
  renderRowSubComponent?: any
}

const CustomRow: FC<Props> = ({ row, getSelectedRow, selectedRow }) => {

  const selectRow = (rowdata: any) => {
    getSelectedRow(rowdata)
  }

  const isSelected = useMemo(() => row.index == selectedRow?.row_id, [selectedRow]);

  return (
    <>
      <tr className='py-0' {...row.getRowProps()}>
        {row.cells.map((cell) => {
          return (
            <td
              {...cell.getCellProps()}
              
              className={` py-1 ${isSelected ? "bg-light-primary" : ""} `}
              onClick={() => selectRow({ row_id: row.id, ...row.original })}
            >
              {cell.render('Cell')}
            </td>
          )
        })}
      </tr>

    </>
  )
}

export default memo(CustomRow)
