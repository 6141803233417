/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { GolbalTestForm } from '../pages/GolbalTestForm'
import { AppContext, socket } from '../Context/appContext'
import { useSelector } from "react-redux";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {

  const { currentUser } = useAuth()

  const [rooms, setRooms] = React.useState<any | undefined>([]);
  const [currentRoom, setCurrentRoom] = React.useState<any | undefined>([]);
  const [members, setMembers] = React.useState<any | undefined>([]);
  const [messages, setMessages] = React.useState<any | undefined>([]);
  const [privateMemberMsg, setPrivateMemberMsg] = React.useState<any | undefined>({});
  const [newMessages, setNewMessages] = React.useState<any | undefined>({});

  return (
    // <AppContext.Provider value={{ socket, currentRoom, setCurrentRoom, members, setMembers, messages, setMessages, privateMemberMsg, setPrivateMemberMsg, rooms, setRooms, newMessages, setNewMessages }}>
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            {/* <Route path='error/*' element={<ErrorsPage />} /> */}
            <Route path='logout' element={<Logout />} />
            <Route path='guest/' element={<GolbalTestForm />} />
            {currentUser ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/dashboard' />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    // </AppContext.Provider>
  )
}

export { AppRoutes }
