import axios from 'axios';
import { URL_EMPLOYEE_ATTENDANCE_URL } from '../../../../app/apis';


const apiData = (postData: any): Promise<any> => {
  return axios
    .post(`${URL_EMPLOYEE_ATTENDANCE_URL}?`,postData)
    .then(async (d: any) => d.data)
    .catch((e: any) => e?.response?.data)
}


export {
  apiData
};

