import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getUserById, getUserByToken } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';



type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, currentUser } = useAuth();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const cookies = new Cookies();

  // console.log({ currentUser, auth, search, pathname });


  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const data = await getUserByToken(apiToken)
          console.log({ getUserByToken: data?.data?.data });

          if (data && data.data.code == 200) {
            // console.log("checking last url");

            const getUserStatus = await getUserById(data?.data?.data?.userId)
            // console.log({ getUserStatus });
            // setCurrentUser({ ...userData.data.data, workStatus: getUserStatus?.work_status || 'PUNCH_OUT', ps_token: getUserStatus?.ps_token })

            setCurrentUser({ ...data.data?.data, workStatus: getUserStatus?.work_status || 'PUNCH_OUT', ps_token: getUserStatus?.ps_token, loginAt:getUserStatus?.login_at,last_logout_at:getUserStatus?.last_logout_at, totalWorkingHours:getUserStatus?.total_working_time })
            if (cookies.get('kt-last-url')) {
              let lastpath = pathname + search || cookies.get('kt-last-url') || "";
              // if (!lastpath) {
              //   lastpath = pathname;
              // }
              navigate({ pathname: lastpath });
            }
          }
          else {
            localStorage.setItem('kt-last-url', pathname + search)
            cookies.set('kt-last-url', pathname + search, { path: '/' });
            logout();
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          localStorage.setItem('kt-last-url', pathname + search)
          cookies.set('kt-last-url', pathname + search, { path: '/' });
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      localStorage.setItem('kt-last-url', pathname + search)
      cookies.set('kt-last-url', pathname + search, { path: '/' });
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])



  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
