import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import axios, { AxiosError, AxiosResponse } from 'axios'

const initialValues = {
  username: '',
}

const forgotPasswordSchema = Yup.object().shape({
  // email: Yup.string()
  //   .email('Wrong email format')
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Email is required'),
  username: Yup.string().test('username', (value: any, validationcontext) => {
    const { createError, parent: { } } = validationcontext;
    if (value && value[0] == 0) {
      return createError({ message: "Phone number must not start with zero" });
    }
    return true;
  }).length(10).required().label('username'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      submitHandler(values, setStatus, setSubmitting)
    },
  })

  const submitHandler = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setHasErrors(undefined)

    const apiResp: AxiosResponse = await requestPassword(values.username);

    console.log({ apiResp });
    try {
      if (apiResp.data?.code === 200) {
        setHasErrors(false)
        setLoading(false)
      }
      else {
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false);
        setErrorMessage(apiResp.data?.message);
      }

    } catch (e) {
      const error = e as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false);
        setErrorMessage(error.response?.data?.message);
        console.log({ error: error.response });
      }
      console.log({ error: error.message });

    }

    // if (apiResp.data?.code === 200) {
    //   setHasErrors(false)
    //   setLoading(false)
    // } else {
    //   setHasErrors(true)
    //   setLoading(false)
    //   setSubmitting(false);
    //   setStatus(apiResp && apiResp?.message);
    // }


    // .then(({ data: { result } }) => {
    //   setHasErrors(false)
    //   setLoading(false)
    // })
    // .catch(() => {
    //   setHasErrors(true)
    //   setLoading(false)
    //   setSubmitting(false);
    //   setStatus('An error occurred while resetting the password');
    // })

  }

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          <img className='mb-5' src={toAbsoluteUrl('/media/logos/I-Disco-Logo-1.png')} style={{ width: '150px' }} />
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Enter your username to reset your password.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {errorMessage}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          {/* <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder='Enter your Email id'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )} */}

          <label className='form-label required'>Username</label>
          <input
            type='text'
            className='form-control form-control-sm form-control-solid mb-3 mb-lg-0'
            placeholder='xxx-xxx-xxxx'
            value={formik.values.username}
            maxLength={11}
            onChange={(e: any) => {
              var username = e.target.value == '' ? '' : e.target.value.replace(/[^0-9]+/g, '');
              // if (phone_no && phone_no[0] === 0) {
              //    const [zero, ...rest] = phone_no;
              //    phone_no = rest.join("");
              // }
              formik.setFieldValue('username', username)
            }}

          // {...formik.getFieldProps('phone')}
          />
          {formik.touched.username && formik.errors.username && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.username}</div>
            </div>
          )}

        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
