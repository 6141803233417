/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useContext, memo, useEffect } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserById, getUserByToken, login } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { useLoginUserMutation } from '../../../reducers/apiSlice'
import { AppContext, AppContextInterface } from '../../../Context/appContext'
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'universal-cookie';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    //.email('Wrong email format')
    //.min(3, 'Minimum 3 symbols')
    //.max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

// const initialValues = {
//   email: '1',
//   password: 'Test1234',
// }

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

const Login = () => {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [user, setuser] = useState<any | undefined>();

  const [loginUser, { isLoading, error }] = useLoginUserMutation();

  const navigate = useNavigate();
  const cookies = new Cookies();


  // socket.off("new-user").on("new-user", (payload: any) => {
  //   setMembers(payload);
  //   const findUser = payload.filter((p: any) => p.email == user.email)[0];
  //   saveAuth({ ...user, _id: findUser._id });
  // });

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        // const { data: auth } = await login(values.email, values.password)
        const { email, password } = values;
        const auth = await login(email, password); //loginUser({ username: email, password }).then(({ data }: any) => data).catch((err: any) => {

        //const auth = await loginUser({ email, password }).then(({ data }: any) => data);
        // console.log({ auth });

        if (auth && auth && auth?.code == 200) {
          const { data, code } = auth;
          let apiTokenData = {
            api_token: auth?.data?.tokens?.access?.token,
            refreshToken: auth?.data?.tokens?.refresh?.token
          };

          apiTokenData && saveAuth(apiTokenData);

          saveAuth({
            api_token: data?.tokens?.access?.token,
            refreshToken: data?.tokens?.refresh?.token
          });

          const userData = await getUserByToken(apiTokenData.api_token)
          console.log({ user: userData.data.data?.userId });
          console.log({ userData });



          if (cookies.get('kt-last-url')) {
            const lastpath = cookies.get('kt-last-url') || "";
            navigate({ pathname: lastpath });
          }

          const getUserStatus = await getUserById(userData?.data?.data?.userId)
          console.log({ getUserStatus });
          setCurrentUser({ ...userData.data.data, workStatus: getUserStatus?.work_status || 'PUNCH_OUT', ps_token: getUserStatus?.ps_token, loginAt: getUserStatus?.login_at, last_logout_at: getUserStatus?.last_logout_at, totalWorkingHours: getUserStatus?.total_working_time })

        }
        else {
          saveAuth(undefined)
          setStatus(auth?.message)
          setSubmitting(false)
          setLoading(false)
        }

      } catch (error: any) {
        // console.log(error.message)
        saveAuth(undefined)
        setStatus(error?.message)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })


  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <img className='mb-5' src={toAbsoluteUrl('/media/logos/I-Disco-Logo-1.png')} style={{ width: '150px' }} />
        <h3 className='text-dark mb-3'>Sign In</h3>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-10 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
        <input
          placeholder='Username'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            {/* <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{ marginLeft: '5px' }}
            >
              Forgot Password ?
            </Link> */}
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          placeholder='********'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */} 

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <Link
        to='/auth/forgot-password'
        className='text-muted text-hover-primary fs-6 fw-bolder'
        style={{ marginLeft: '5px' }}
      >
        Forgot Password ?
      </Link>

      </div>
      {/* end::Action */}
    </form>
  )
}


export default Login