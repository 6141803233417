/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { Languages } from './Languages'
import { convertToCamelCase, toAbsoluteUrl } from '../../../helpers'
import { BiFemaleSign, BiMaleSign } from 'react-icons/bi'
import { RiUser6Fill } from 'react-icons/ri'
import ModelComponent from '../../../../app/modules/Commons/Models/ModelComponent'
import { ChangePasswordForm } from '../../../../app/modules/auth/components/ChangePasswordForm'
import ProfileImageCOmponent from '../../../../app/modules/Commons/Components/ProfileImageCOmponent'

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const userrole = useMemo(() => {
    if (currentUser?.roles?.includes('MARKETERS')) return 'MARKETERS'
    else if (currentUser?.roles?.includes('FEEDER_MANAGER')) return 'FEEDER MANAGER'
    else if (currentUser?.roles?.includes('KCG')) return 'KCG'
    else if (currentUser?.roles?.includes('NETWORK_MANAGER')) return 'NETWORK MANAGER'
    else if (currentUser?.roles?.includes('OP_TECH')) return 'OPERATIONAL TECH'
    else if (currentUser?.roles?.includes('PPM')) return 'PPM'
    else return "EMPLOYEE"
  }, currentUser?.roles);


  const openChangePasswordForm = () => {
    // updateState({
    //     other: {
    //         openReconnectionModal: true,
    //     }
    // })

    setIsOpen(true)
  }



  return (<>


    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-300px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          {/* <div className='symbol symbol-50px me-5'>
            {currentUser && currentUser?.profile_img_url ? <img alt='Logo' src={toAbsoluteUrl(currentUser && currentUser?.profile_img_url || '')} /> : <RiUser6Fill size={24} />}
          </div> */}

          
           {
                currentUser && currentUser?.profile_img_url ? <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label bg-light'>
                        <ProfileImageCOmponent urlProps={currentUser && currentUser?.profile_img_url} />
                    </span>
                </div> : <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label bg-light'><img
                        src={toAbsoluteUrl('/media/svg/avatars/blank.svg')}
                        className='w-40px h-75 rounded '
                        alt=''
                    />   </span>
                </div>
            }
          

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.name}
              {/* <span className='badge badge-light fw-bolder fs-8 px-2 py-1 ms-2'>
                {currentUser?.gender?.toLowerCase() == "male" ? <BiFemaleSign className='fw-bolder' size={15} /> : null}
                {currentUser?.gender?.toLowerCase() == "female" ? <BiMaleSign className='fw-bolder' size={15} /> : null}
              </span> */}
            </div>
            {/* <div>{currentUser?.roles && currentUser?.roles?.length > 0 &&
              currentUser?.roles?.map((role, indx) => {
                return <>
                  <span key={role} className='fw-bold badge badge-light-success badge-sm'>{role}</span>
                </>
              })
            }</div> */}
            <div className='fw-bold badge badge-light-success badge-sm'>{userrole}</div>
            {/* <span className='fw-bold text-muted fs-7'>
              {currentUser?.first_name + " " + currentUser?.last_name || ''}
            </span> */}
            {/* <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.gender}
            </a> */}
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {currentUser?.gender && <div className='menu-item px-5 d-flex justify-content-between'>
        <span className='text-muted fs-7 px-5'> Gender</span>
        <span className='fw-bold text-dark fs-7 px-5'> {convertToCamelCase(currentUser?.gender) || ''}</span>
      </div>}
      {currentUser?.phone && <div className='menu-item px-5 d-flex justify-content-between'>
        <span className='text-muted fs-7 px-5'> Phone no</span>
        <span className='fw-bold text-dark fs-7 px-5'>{currentUser?.phone || ""}</span>
      </div>}
      {currentUser?.email && <div className='menu-item px-5 d-flex justify-content-between'>
        <div className='text-muted fs-7 px-5'>Email</div>
        <span className='fw-bold text-dark fs-8 px-5 text-nowrap'>{currentUser?.email || ""}</span>
      </div>}
      <div className='separator'></div>
      {/* 
      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}

      <div className='menu-item px-5'>
        <a onClick={openChangePasswordForm} className='menu-link px-5'>
          Change Password
        </a>
      </div>
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>

    <ModelComponent isOpen={isOpen} title='Change Password' onHide={() => setIsOpen(!isOpen)}>
      <ChangePasswordForm onClose={(e: any) => setIsOpen(e)} />
    </ModelComponent>
  </>
  )
}

export { HeaderUserMenu }
