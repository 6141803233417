import React, { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
// import { resetPassword } from '../core/_requests'; // Change this to your reset password request function
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { requestPasswordReset } from '../core/_requests';
import { AxiosResponse } from 'axios';
import Login from './Login';
import { toast } from 'react-toastify';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';

const initialValues = {
    newPassword: '',
    confirmPassword: '',
};

const resetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
        .min(8, 'Minimum 8 characters')
        .max(50, 'Maximum 50 characters')
        .required('New password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm password is required'),
});

export function ResetPassword() {


    const { search } = useLocation();
    const navigate = useNavigate();

    const token = search && search?.split('?')[1].split("=")[1];

    // console.log({ token })

    const [loading, setLoading] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            submitHandler(values, setStatus, setSubmitting)
        },
    })
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);

    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword)
    };

    const submitHandler = async (values: any, setStatus: any, setSubmitting: any) => {
        setLoading(true);
        setHasErrors(false);

        // You should replace the following requestPassword function with your reset password request
        const apiResp: AxiosResponse = await requestPasswordReset(values.newPassword, token)
        if (apiResp.data?.code === 200) {
            setHasErrors(false)
            setLoading(false)
            toast.success("Password Changed Successfully")
            setTimeout(() => {
                navigate("/");
            }, 3000);

        } else {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false);
            // toast.error(message)
            setStatus('An error occurred while resetting the password');
        }
        // .then(() => {
        //     setLoading(false);
        //     // Handle success, e.g., show a success message
        // })
        // .catch((error) => {
        //     setLoading(false);
        //     setSubmitting(false);
        //     setStatus('An error occurred while resetting the password');
        // });
    }

    // Handle errors, e.g., show an error message


    return (
        <>
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_reset_password_form'
                onSubmit={formik.handleSubmit}
            >
                <div className='text-center mb-10'>
                    <img
                        className='mb-5'
                        src={toAbsoluteUrl('/media/logos/I-Disco-Logo-1.png')}
                        style={{ width: '150px' }}
                    />
                    <h1 className='text-dark mb-3'>Reset Password</h1>
                    <div className='text-gray-400 fw-bold fs-4'>
                        Enter your new password and confirm it.
                    </div>
                </div>

                {hasErrors && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>
                            Sorry, an error occurred. Please try again.
                        </div>
                    </div>
                )}

                {/* New Password */}
                <div className='fv-row mb-10'>
                    <label className='form-label fw-bolder text-gray-900 fs-6'>
                        New Password
                    </label>
                    <div className='input-group'>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            autoComplete='off'
                            {...formik.getFieldProps('newPassword')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid':
                                        formik.touched.newPassword && formik.errors.newPassword,
                                },
                                {
                                    'is-valid':
                                        formik.touched.newPassword && !formik.errors.newPassword,
                                }
                            )}
                        />
                         <span className='input-group-text cursor-pointer'>
                            {showConfirmPassword ? (
                                <RiEyeOffFill onClick={togglePasswordVisibility} />
                            ) : (
                                <RiEyeFill onClick={togglePasswordVisibility} />
                            )}
                        </span>
                    </div>
                    {formik.touched.newPassword && formik.errors.newPassword && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.newPassword}</span>
                            </div>
                        </div>
                    )}
                </div>

                {/* Confirm Password */}
                <div className='fv-row mb-10'>
                    <label className='form-label fw-bolder text-gray-900 fs-6'>
                        Confirm Password
                    </label>
                    <div className='input-group'>
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            autoComplete='off'
                            {...formik.getFieldProps('confirmPassword')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid':
                                        formik.touched.confirmPassword &&
                                        formik.errors.confirmPassword,
                                },
                                {
                                    'is-valid':
                                        formik.touched.confirmPassword &&
                                        !formik.errors.confirmPassword,
                                }
                            )}
                        />
                        <span className='input-group-text cursor-pointer'>
                            {showConfirmPassword ? (
                                <RiEyeOffFill onClick={toggleConfirmPasswordVisibility} />
                            ) : (
                                <RiEyeFill onClick={toggleConfirmPasswordVisibility} />
                            )}
                        </span>
                    </div>
                    {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.confirmPassword}</span>
                            </div>
                        </div>
                    )}
                </div>

                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <button
                        type='submit'
                        id='kt_reset_password_submit'
                        className='btn btn-lg btn-primary fw-bolder me-4'
                    >
                        <span className='indicator-label'>Submit</span>
                        {loading && (
                            <span className='indicator-progress'>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <Link to='/auth/login'>
                        <button
                            type='button'
                            id='kt_reset_password_form_cancel_button'
                            className='btn btn-lg btn-light-primary fw-bolder'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            Cancel
                        </button>
                    </Link>{' '}
                </div>
            </form>
        </>
    );
}
