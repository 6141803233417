import { createSlice } from "@reduxjs/toolkit";
import appApi from "./apiSlice";



export const userSlice = createSlice({
    name: "user",
    initialState: null,
    reducers: {
        addNotifications: (state: any, { payload }) => {
            // console.log({ state, payload });

            if (state.newMessages[payload]) {
                state.newMessages[payload] = state.newMessages[payload] + 1;
            } else {
                state.newMessages[payload] = 1;
            }

            // console.log({ state });

        },
        resetNotifications: (state: any, { payload }) => {
            if (payload)
                delete state.newMessages[payload];
        },
    },

    extraReducers: (builder) => {
        // save user after signup
        builder.addMatcher(appApi.endpoints.signupUser.matchFulfilled, (state, { payload }) => payload);
        // save user after login
        builder.addMatcher(appApi.endpoints.loginUser.matchFulfilled, (state, { payload }) => payload);
        // logout: destroy user session
        builder.addMatcher(appApi.endpoints.logoutUser.matchFulfilled, () => null);
    },
});

export const { addNotifications, resetNotifications } = userSlice.actions;
export default userSlice.reducer;
