// @ts-nocheck
import { FC } from 'react'
import { ColumnInstance } from 'react-table'

type Props = {
  column: ColumnInstance<any>
}

const CustomHeaderColumn: FC<Props> = ({ column }) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()} className={'p-0'} >
        <div className={column?.className+" p-2 "}>
          {column.render('Header')}
        </div>
      </th>
    ) : (
      column.render('Header')
    )}
  </>
)

export { CustomHeaderColumn }
