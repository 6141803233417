import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// define a service user a base URL

const API_URL = process.env.REACT_APP_BACKEND_URL; //'http://194.163.171.207:30098'; //

const GET_USER_BY_ACCESSTOKEN_URL = `/verify_token`
const LOGIN_URL = `/login`
const REGISTER_URL = `/register`
const REQUEST_PASSWORD_URL = `/forgot_password`

const chatsApiSlice = createApi({
    reducerPath: "chatsApiSlice",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
    }),

    endpoints: (builder) => ({
        // creating the user
        userGroups: builder.mutation({
            query: (payload) => ({
                url: "/users/groups",
                method: "POST",
                body: payload,
            }),
        }),
        
        groupUsers: builder.mutation({
            query: (payload) => ({
                url: "/users/groups-users/" + payload._id,
                method: "GET",
                //body: payload,
            }),
        }),

        createGroup: builder.mutation({
            query: (payload) => ({
                url: "/create-room",
                method: "POST",
                body: payload,
            }),
        }),
    }),
});

export const { useUserGroupsMutation, useCreateGroupMutation, useGroupUsersMutation } = chatsApiSlice;

export default chatsApiSlice;
