import React, { useEffect, useState, useMemo, useRef, memo, useReducer, useCallback } from 'react';
import { toAbsoluteUrl } from '../../../../../../../../../../_metronic/helpers';
import ProfileImageCOmponent from '../../../../../../../../Commons/Components/ProfileImageCOmponent'
import { RiPhoneFill } from 'react-icons/ri';

type Props = {
    rowData: any
}

const ConsumerInfoDetails: React.FC<Props> = ({ rowData }) => {
    return <>
        <div className='d-flex align-items-center'>
            {/* {
                rowData?.profile_image_url && <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label bg-light'>
                        <ProfileImageCOmponent urlProps={rowData?.employee_profile_image_url } />
                    </span>
                </div>
            } */}

            <div className='d-flex justify-content-start flex-column' data-bs-toggle='tooltip' title='Consumer Name'>
                <span className='text-dark fw-bold mb-1 fs-6'>
                    {(rowData?.cname) || '-'}
                </span>
                <span className='text-muted fw-semibold text-muted d-block fs-7' data-bs-toggle='tooltip' title='Consumer No'>
                    <span className='badge badge-light'>   #  </span> <span className='text-dark'>{(rowData?.consumer_no || "-")}</span>
                </span>
                <span className='text-muted fw-semibold text-muted d-block fs-7' data-bs-toggle='tooltip' title='Consumer Phone'>
                    <span className='badge badge-light'>   <RiPhoneFill  size={12} /> </span><span className='text-dark'>{(rowData?.cphone || "-")}</span>
                </span>
            </div>
        </div>
    </>
}

export default ConsumerInfoDetails