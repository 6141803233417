// @ts-nocheck
import { Column } from 'react-table'
import { UserCustomHeader } from './UserCustomHeader'
import { User } from '../../../core/_models';
import ViewImage from './ViewImage';
import ActionsColumn from './ActionsColumn'
import ConsumerInfoDetails from './ConsumerInfoDetails'
import MarketersInfoColumn from './MarketersInfoColumn'
import RowActions from './RowActions'
import { convertToCamelCase, getFormatDateKLocale } from '../../../../../../../../../../_metronic/helpers'


const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Sr.No.' className='min-w-25px' />,
    accessor: "sr_no",
    Cell: ({ ...props }) => <div className='text-center'>{props.value}</div>
  },
   {
    Header: (props) => <UserCustomHeader tableProps={props} title='Consumer' className='min-w-125px' sortable={false} />,
    id: "consumer",
    Cell: ({ ...props }) => <ConsumerInfoDetails rowData={props.row.original}></ConsumerInfoDetails>
  },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Consumer No' className='min-w-125px' sortable={false} />,
  //   accessor: "consumer_no",
  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Consumer Phone' className='min-w-125px' sortable={false} />,
  //   accessor: "phone",
  // },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Performed by' className='min-w-125px' sortable={false} />,
    id: "user",
    Cell: ({ ...props }) => <MarketersInfoColumn rowData={props.row.original}></MarketersInfoColumn>
  },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Order Name' className='min-w-125px' sortable={false} />,
  //   accessor: "order_name",
  // },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Status' className='max-w-125px' sortable={false} />,
    accessor: "order_status",
    Cell: ({ ...props }) => <div className='text-center'>
      {props.value === "ISSUED" ? <div class="badge badge-sm badge-light-orange fw-bolder">{convertToCamelCase(props.value)}</div> : null}
      {props.value === "DONE" ? <div class="badge badge-sm badge-light-danger fw-bolder">{convertToCamelCase(props.value)}</div> : null}
      {props.value === "NOT_DONE" ? <div class="badge badge-sm badge-light-warning fw-bolder">{convertToCamelCase(props.value)}</div> : null}
      {props.value === "CLOSED" ? <div class="badge badge-sm badge-light-success fw-bolder">{convertToCamelCase(props.value)}</div> : null}
    </div>
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Amount' className='min-w-125px' sortable={false} />,
    accessor: "order_amount",
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Order No.' className='min-w-125px' sortable={false} />,
    accessor: "order_no",
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Reason' className='min-w-125px' sortable={false} />,
    accessor: "event_reason",
    Cell: ({ ...props }) => <div className='text-center'>
      {props.value ? convertToCamelCase(props.value) : "-"}
    </div>
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Performed at' className='min-w-125px' sortable={false} />,
    accessor: "event_at",
    Cell: ({ ...props }) => props.value && props.value ? getFormatDateKLocale(props.value) : "-"
  },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='CA. No.' className='min-w-125px' sortable={false} />,
  //   accessor: "consumer_no"
  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Employee ID' className='min-w-125px' sortable={false} />,
  //   accessor: "employee_id"
  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Amount' className='min-w-125px' sortable={false} />,
  //   accessor: "pending_amount", 
  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Date' className='min-w-125px' sortable={true} />,
  //   accessor: "last_payment_date",
  //   Cell: ({ ...props }) => props.value ? <Moment format={'DD/MM/YYYY'}>{props.value}</Moment> : '-'

  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Order No.' className='min-w-125px' sortable={false} />,
  //   accessor: "order_no",
  //   Cell: ({ ...props }) => props.value ? <div>{props.value}</div> : "-"
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Observation' className='min-w-125px' sortable={false} />
  //   ),
  //   accessor: "event_name",
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='RC Status' className='min-w-125px' sortable={false} />
  //   ),
  //   accessor: "event_state",
  //   Cell: ({ ...props }) =>
  //     <>
  //       {props.value == "RECONNECTED" ? <div className='badge badge-light-success fw-bolder'>RECONNECTED</div> : null}
  //       {props.value == "REQUESTED" ? <div className='badge badge-light-warning fw-bolder'>REQUESTED</div> : null}
  //       {props.value == "NOT_RECONNECTED" ? <div className='badge badge-light-warning fw-bolder'>NOT-RECONNECTED</div> : null}
  //       {props.value == "PENDING" ? <div className='badge badge-light-danger fw-bolder'>PENDING</div> : null}
  //     </>
  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Disc. Date' className='min-w-125px' sortable={true} />,
  //   accessor: "event_ts",
  //   Cell: ({ ...props }) => props.value ? <Moment format={'DD/MM/YYYY'}>{props.value}</Moment> : '-'
  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Reason' className='min-w-125px' sortable={false} />,
  //   accessor: "reason_code",
  //   Cell: ({ ...props }) => props.value ? <div className='text-grey '>{REASONCODES[props.value]}</div> : <div className='text-muted'>NA</div>
  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Remarks' className='min-w-125px' sortable={false} />,
  //   accessor: "remarks",
  //   Cell: ({ ...props }) => props.value ? <div>{props.value}</div> : "-"
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='images' className='max-w-125px' sortable={false} />
    ),
    accessor: "images_url",
    Cell: ({ ...props }) => props.value ? <ViewImage propsimages={props.value} /> : "NA"
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Sign.' className='max-w-125px' sortable={false} />,
    accessor: "signature_url",
    Cell: ({ ...props }) => <ActionsColumn rowData={props.row.original} />
  },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Actions' className='w-75px' sortable={false} />,
  //   id: "Action",
  //   Cell: ({ ...props }) => <RowActions rowData={props.row.original} />
  // }
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='actions' className='max-w-125px' sortable={false} />
  //   ),
  //   id: "actions",
  //   Cell: ({ ...props }) => <ReconnectionModel isDisconnected={props.row.original.event_state} />
  // },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Details' className='w-75px' sortable={false} />,
    id: "Action",
    Cell: ({ ...props }) => <RowActions rowData={props.row.original} />
  }
]

export { usersColumns }


//props.value instanceof Array && props.value?.length > 0 ? <div className='text-success'>Image</div> : <div className='text-light'>None</div>