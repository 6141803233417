import Viewer from 'react-viewer';
import axios, { AxiosResponse } from 'axios'
import React, { useState, useEffect } from 'react'
import { DisconnectionReportState } from '../../..';


type ActionsColumnProps = { rowData: any }; /* could also use interface */

const RowActions = ({ rowData }: ActionsColumnProps) => {

    const { state, updateState } = DisconnectionReportState();

    const setRowData = () => {
        updateState({
            other: {
                ...state.other,
                formData: rowData,
                showDescription:true
            }
        })
    }

    // console.log({ state });


    return <>
        {rowData?.event_id ? <button className='btn btn-light-primary btn-sm py-2' onClick={setRowData}>show</button> : "-"}
    </>
}

export default RowActions;
