import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../../../_metronic/helpers'
import { URL_ORDERS_CONSUMERS_LIST } from '../../../../../../../apis'




const fetchReport = (query: string): Promise<any> => {
  return axios
    .post(`${URL_ORDERS_CONSUMERS_LIST}?${query}`, { "name": "RECONNECTION" })
    .then((d: AxiosResponse<any>) => d.data.data)
    .catch((e: any) => e?.response?.data)
}

export {
  fetchReport
}
