import * as React from 'react';
import Viewer from 'react-viewer';
import { KTSVG } from '../../../../../../../../../../_metronic/helpers'
import axios, { AxiosResponse } from 'axios'
import { BiImages } from 'react-icons/bi';

interface IViewImageProps {
    propsimages: any,
    rowData: any
}

const ViewImage: React.FunctionComponent<IViewImageProps> = ({ propsimages, rowData }) => {


    // console.log({ rowData });

    const API_URL = process.env.REACT_APP_API_URL_REPORT_SERVICE;


    const [visible, setVisible] = React.useState(false);
    const [images, setImages] = React.useState<any>([{
        src: "",
        alt: ""
    }]);

    React.useEffect(() => {
        let allImages: any = [];
        rowData.images_url && rowData.images_url.forEach((img: any) => {
            allImages[allImages.length] = { src: img, alt: '' }
        });
        setImages(allImages)
        return () => { }
    }, [visible])


    // console.log("env variable ",);

    // const arrangeImagesWithRemark = (arr: any) => {
    //     return arr.map((data: any) => {
    //         return {
    //             src: data.url,
    //             alt: (data.remarks || "") + "" + (data.reason_code ? " [" + data.reason_code + "]" : "")
    //         }
    //     })
    // }


    // const fetchImagesFromEventID = async (event_id: string) => {
    //     try {
    //         const url = `/api/v1/event/${event_id}/images`
    //         const apiResp = await axios
    //             .get(`${API_URL}${url}`)
    //             .then((d: AxiosResponse<any>) => d.data.data);
    //         console.log({ resp: apiResp });
    //         apiResp && setImages(arrangeImagesWithRemark(apiResp))
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // }

    const viewImage = () => {

    }

    return <>
        <div>{
            propsimages instanceof Array && propsimages?.length > 0 ?
                <span onClick={() => setVisible(!visible)} className='cursor-pointer text-primary text-hover-primary'>
                    {/* <KTSVG path='/media/icons/duotune/general/gen006.svg' className='svg-icon-1' /> */}
                    <BiImages size={16} className="text-primary" />
                </span> :
                <span className='cursor-pointer text-muted'>
                    {/* <KTSVG path='/media/icons/duotune/general/gen006.svg' className='svg-icon-1' /> */}
                    <BiImages size={16} className="text-muted" />
                </span>
        }</div>

        {visible && < Viewer
            visible={visible}
            onClose={() => setVisible(!visible)}
            images={images}

        />}
    </>;
};

export default ViewImage;


{/* <div className='text-success' onClick={() => setVisible(true)} >Image</div> */ }